// @ts-nocheck
import React, { useState, memo, useEffect, useMemo } from 'react';
import {
  Paper,
  Box,
  Button,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  LinearProgress,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Snackbar,
  Input,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavigationTopBar } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, startCase } from 'lodash';
import {
  orderDetailApiV2,
  orderStatusApi,
  promotedOrderApi,
  refundOrder,
  refundOrderNew,
  serialNumberApi,
  saveOrderNoteApi,
  resendOrderReceipt,
} from 'src/services/orders';
import { changeCCOrderStatusApi } from 'src/services/clickAndCollect';
import {
  constructUIData,
  getCurrency,
  getOrderCount,
  currencyFormat,
  orderStatus,
  updateDecimals,
  validateEAN13,
  validateITF14,
  validateUPC,
  verificationReasons,
} from 'src/utils';
import moment from 'moment';
import BarcodeShow from 'src/assets/svgIcons/BarcodeShow';
import ExternalLinkIcon from 'src/assets/svgIcons/ExternalLinkIcon';
import RefundSelector from './components/RefundSelector';
import SerialNumber from './components/SerialNumber';
import { useImmer } from 'use-immer';
// @ts-ignore
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import OrderNote from './components/OrderNote';
import AlertIcon from '../../assets/svgIcons/AlertIcon';
import { datadogRum } from '@datadog/browser-rum';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  header: {},
  body: {
    flex: 1,
    overflow: 'scroll',
    marginBottom: 92,
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 84,
    backgroundColor: 'white',
  },
  statusButton: {
    height: 40,
    width: '100%',
    [theme.breakpoints.between('lg', 'xl')]: {
      width: 210,
    },
    fontWeight: 'bold',
    backgroundColor: (props: any) =>
      `${theme.palette[props.color ? props.color : 'info'].main}${
        props.variant === 'outlined' ? '33' : ''
      }`,
    border: (props: any) =>
      `solid 1px ${theme.palette[props.color ? props.color : 'info'].main}`,
    textTransform: 'none',
    color: (props: any) =>
      props.variant === 'outlined'
        ? theme.palette[props.color ? props.color : 'info'].main
        : theme.palette.color_white.main,
    fontSize: 15,
  },

  buttonLeft: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
    border: (props: any) => `1px solid ${theme.palette[props.b1bg].main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette[props.b1bg].main,
  },

  ccButtonLeft: {
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    border: (props: any) => `1px solid ${theme.palette.primary.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette.primary.main,
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    },
  },
  emailModalContent: {
    display: 'inherit',
    textAlign: 'center',
  },
  username: {
    width: '100%',
    marginBottom: '20px',
  },
  buttonRefundLeft: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
    border: (props: any) => `1px solid ${theme.palette.primary.main}`,
    height: '40px',
    fontWeight: 'bold',
    borderWidth: 1,
    color: (props: any) => theme.palette.primary.main,
  },

  buttonRight: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette[props.b2bg].main}`,
    },
  },

  ccButtonRight: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.color_white.main,
    marginLeft: 16,
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    },
  },

  buttonRefundRight: {
    width: '167px',
    backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    color: theme.palette.color_white.main,
    margin: '0 16px',
    height: '40px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: (props: any) => `${theme.palette.primary.main}`,
    },
  },

  buttonLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette[props.b1bg].main}1A`,
  },
  buttonRightRipple: {
    color: (props: any) => `${theme.palette[props.b2bg].main}`,
  },

  buttonRefundLeftRipple: {
    backgroundColor: (props: any) => `${theme.palette.primary.main}1A`,
  },
  buttonRefundRightRipple: {
    color: (props: any) => `${theme.palette.primary.main}`,
  },
  buttonMarkAsCollectedRipple: {
    color: (props: any) => `${theme.palette.primary.main}`,
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  bodyContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  orderSummary: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    alignItems: 'center',
    backgroundColor: '#f2f6ff',
    marginTop: '1px',
  },

  orderSummaryContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: 16,
    },
    [theme.breakpoints.between('xs', 'md')]: {
      marginTop: 8,
    },
    alignItems: 'flex-start',
  },

  orderSummaryListContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    alignItems: 'flex-start',
    padding: 2,
  },
  specialInstructionsContent: {
    background: '#ff00002b',
    padding: '2px 5px',
    width: 'fit-content',
  },
  darkRedFont: {
    color: '#700940',
    fontSize: '12px',
  },
  lightGreyTextList: {
    fontSize: 12,
    color: theme.palette.color_text_grey.main,
  },
  darkTextList: {
    fontSize: 12,
    color: theme.palette.color_dark_text.main,
  },

  lightGreyText: {
    fontSize: 13,
    color: theme.palette.color_text_grey.main,
  },
  darkText: {
    fontSize: 13,
    color: theme.palette.color_dark_text.main,
  },
  outerBody: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },

  innerBody: {
    display: 'flex',
    maxWidth: 1160,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
  },

  orderSummaryText: {
    fontSize: 22,
    color: theme.palette.color_dark_text.main,
    fontWeight: 'bold',
    marginTop: 8,
  },

  orderSummaryUI: {
    width: '100%',
    height: '100%',
  },

  promotedLayout: {
    marginTop: '8px',
  },

  itemImage: {
    width: '38px',
    height: '38px',
    objectFit: 'contain',
  },

  itemImageContainer: {
    width: '38px',
    height: '38px',
    marginRight: 16,
  },

  imageAndtitleContainer: {
    display: 'flex',
    width: '50%',
  },
  itemName: {
    fontSize: '13px',
    color: theme.palette.color_dark_text.main,
  },
  itemCategory: {
    fontSize: '12px',
    color: theme.palette.color_text_grey.main,
  },

  promoBox: {
    marginTop: 8,
    display: 'flex',
  },

  promoText: {
    backgroundColor: theme.palette.promo_background.main,
    padding: '2px 4px 4px',
    fontSize: '12px',
    color: theme.palette.color_dark_text.main,
    marginRight: 10,
  },
  rfidTag: {
    border: '1px solid #0A7013',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.color_dark_green.main,
    padding: '2px 4px 4px',
  },
  barcodeShow: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '12px',
    marginRight: 50,
  },

  serialNumberShow: {
    '&:disabled': {
      color: '#000000',
    },
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
  },

  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
    display: 'flex',
  },

  mobileLoaderContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  mobileLoader: {
    width: 200,
    height: 100,
    backgroundColor: '#CCCCCC',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  circle: {
    marginRight: 20,
  },

  arrow: {
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
      marginTop: -6,
      marginLeft: -2,
    },
  },
}));

const OrderDetails = () => {
  const theme: any = useTheme();
  const history: any = useHistory();
  const [cookies] = useCookies();
  const queryClient = useQueryClient();
  const intl = useIntl();

  let props_order_id = '';
  let type: any = null;
  let from = null;

  if (history && history.location && history.location.state) {
    props_order_id = history.location.state.order_id
      ? history.location.state.order_id
      : '';
    type = history.location.state.type ? history.location.state.type : null;
    from = history.location.state.from ? history.location.state.from : null;
  } else {
    props_order_id = history.location.pathname.split('/')[2];
  }

  let barcodeInit = { visible: false, type: '', data: '' };
  let serialNumberPopUpInit = {
    show: false,
    old_sn: '',
    new_sn: '',
    item_id: '',
  };
  const [userEmail, setInput] = useState('');
  const [items_to_refund, setItemsToRefund] = useImmer([]);
  const [alertVisible, showAlert] = useState(false);
  const [ccAlertVisible, showCCAlert] = useState(false);
  const [emailReceiptClicked, showEmailReceipt] = useState(false);
  const [barcode, showBarcode] = useState(barcodeInit);
  const [serialNumberPopUpData, showSerialNumberPopUp] = useState(
    serialNumberPopUpInit,
  );
  const [items_to_capture_sns, setItemsForCapturingSNS] = useImmer({} as any);
  const [shouldRefundAll, setRefundAll] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
  const [active_store, setActiveStore] = useState({
    promo_version: 1,
    store_id: '',
    is_refund_allow: false,
    currency: 'gbp',
    retailer: '',
  });
  const [is_refund_allow, setRefundAllow] = useState(false);
  const [is_mark_collected_allowed, setMarkCollectedAllowed] = useState(false);
  const [is_serial_number_allow, setSerialNumberAllow] = useState(false);
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const [cancelCCOrder, setCancelCCOrder] = React.useState(false);

  let { active_stores, include_demo_stores, include_inactive_stores } =
    useSelector((state: any) => state.store);
  let { can_refund } = useSelector((state: any) => state.user.permissions);

  const fetchOrderDetailsV2 = () => {
    return orderDetailApiV2
      .get({
        order_id: props_order_id,
        v1: true,
        token: cookies.app_token,
        include_demo: include_demo_stores,
        include_inactive: include_inactive_stores,
      })
      .catch((e: Error) => {
        console.log({ e });
        console.log(`failed to fetch the order`);
        showToast({
          visible: true,
          message: `Failed to fetch the order`,
          severity: 'error',
        });
        window.history.back();
      });
  };

  const {
    isLoading,
    error,
    data: orderResponse,
    isFetching,
  } = useQuery(['ordersData', props_order_id], fetchOrderDetailsV2);

  const orderData = get(orderResponse, 'data.data', {});
  const order_id = get(orderData, 'order_id', '');
  const display_o_id = get(orderData, 'o_id', '');
  const store_id = get(orderData, 'store_id', '');

  useEffect(() => {
    try {
      if (store_id) {
        let active_store = active_stores.filter(
          (store: any) => store.store_id === store_id,
        )[0];
        if (active_store) {
          setActiveStore(active_store);
          setRefundAllow(active_store?.is_refund_allow && can_refund);
          setMarkCollectedAllowed(active_store?.features_applicable.deliver);
          setSerialNumberAllow(
            active_store?.features_applicable.dashboard_item_serial_number,
          );
        } else throw 'Store not found';
      }
    } catch (e) {
      console.log({ e });
    }
  }, [store_id]);

  let { promo_version = 1, currency: storeCurrency, retailer } = active_store;

  const fetchPromoOrder = () => {
    return promotedOrderApi.query({
      format: 'json',
      store_id,
      order_id,
      v1: true,
      token: cookies.app_token,
    });
  };

  const { isLoading: promoLoading, data: promoResponse } = useQuery(
    ['promoData', order_id],
    fetchPromoOrder,
    { enabled: promo_version == 2 && order_id ? true : false },
  );

  const handleOrderStatus = (payload: any) => {
    return orderStatusApi.create(
      {
        v1: true,
        store_id,
        token: cookies.app_token,
      },
      JSON.stringify(payload),
    );
  };

  const handleCCOrderStatus = (payload: any) => {
    return changeCCOrderStatusApi.create(
      { v1: true, token: cookies.app_token },
      JSON.stringify(payload),
    );
  };

  const handleOrderRefundApi = (payload: any) => {
    try {
      if (promo_version === 1) {
        return refundOrder.create(
          { v1: true, token: cookies.app_token },
          JSON.stringify(payload),
        );
      } else if (promo_version === 2) {
        return refundOrderNew.create(
          { v1: true, token: cookies.app_token },
          JSON.stringify(payload),
        );
      }
    } catch (e) {
      console.log({ e });
      throw e;
    }
  };

  const handleSerialNumberApi = () => {
    return serialNumberApi.create(
      { v1: true, token: cookies.app_token },
      JSON.stringify({
        store_id,
        order_id,
        serial_numbers_data: Object.values(items_to_capture_sns),
      }),
    );
  };

  const handlePrintReceiptApi = () => {
    const isVirginMegaStore =
      active_store['store_type'] === 'VirginStoreType' ? true : false;
    datadogRum.addAction('dashboard-print-invoice');
    if (isVirginMegaStore) {
      return axios.get(
        `https://${process.env['REACT_APP_PRINT_RECEIPT_API_HOST']}/${process.env['REACT_APP_PRINT_RECEIPT_API_ENV']}/order-preview/${order_id}`,
        {
          timeout: 800000,
          headers: {
            Authorization: 'Token ' + cookies.app_token,
          },
        },
      );
    } else {
      return axios.get(
        `https://${process.env['REACT_APP_PRINT_RECEIPT_API_HOST']}/${process.env['REACT_APP_PRINT_RECEIPT_API_ENV']}/v1/order-preview/${order_id}`,
        {
          timeout: 800000,
        },
      );
    }
  };

  const handleSaveOrderNoteApi = (order_note: string) => {
    return saveOrderNoteApi.create(
      { v1: true, token: cookies.app_token },
      JSON.stringify({
        order_id,
        notes: order_note,
      }),
    );
  };

  const { isLoading: refundLoading, mutate: triggerRefunds } = useMutation(
    handleOrderRefundApi,
    {
      onSuccess: (data: any) => {
        if (data?.data?.status !== 200) {
          showToast({
            visible: true,
            message: data?.data?.message,
            severity: 'error',
          });
        } else {
          console.log('Refund Successfull');
          queryClient.invalidateQueries('ordersData');
          queryClient.invalidateQueries('promoData');
          if (type === 'click_and_collect') {
            queryClient.invalidateQueries('ccOrdersData');
          }
          showToast({
            visible: true,
            message: intl.formatMessage({ id: 'refundSuccess' }),
            severity: 'success',
          });
          setItemsToRefund([]);
        }
        setCancelCCOrder(false);
      },
      onError: (error: any) => {
        setCancelCCOrder(false);
        console.log({ error });
      },
    },
  );

  const { isLoading: orderStatusLoading, mutate: mutateOrderStatus } =
    useMutation(handleOrderStatus, {
      onSuccess: () => {
        queryClient.invalidateQueries('ordersData');
        queryClient.invalidateQueries('promoData');
      },
      onError: (error: any) => {
        console.log({ error });
      },
    });

  const { isLoading: changeCCOrdersLoading, mutate: changeCCOrderStatus } =
    useMutation(handleCCOrderStatus, {
      onSuccess: (response: any) => {
        if (response?.data?.status === 200) {
          queryClient.invalidateQueries('ordersData');
          queryClient.invalidateQueries('promoData');
          if (type === 'click_and_collect') {
            queryClient.invalidateQueries('ccOrdersData');
          }
          showToast({
            visible: true,
            message: intl.formatMessage({ id: 'statusChanged' }),
            severity: 'success',
          });
        } else {
          showToast({
            visible: true,
            message: response?.data?.message,
            severity: 'error',
          });
        }
      },

      onError: error => {
        showToast({
          visible: true,
          message: intl.formatMessage({ id: 'statusChangeError' }),
          severity: 'error',
        });
      },
    });

  const { isLoading: serialNumbersLoading, mutate: mutateSerialNumbers } =
    useMutation(handleSerialNumberApi, {
      onSuccess: (response: any) => {
        if (response?.data?.status === 200) {
          queryClient.invalidateQueries('ordersData');
          queryClient.invalidateQueries('promoData');
          showToast({
            visible: true,
            message: response?.data?.message,
            severity: 'success',
          });
          setItemsForCapturingSNS({});
        } else {
          showToast({
            visible: true,
            message: response?.data?.message,
            severity: 'error',
          });
        }
      },
      onError: (error: any) => {
        console.log({ error });
      },
    });
  const emailModal = () => {
    return (
      <>
        <Box className={classes.emailModalContent}>
          <Dialog open={emailReceiptClicked}>
            <DialogTitle>
              {intl.formatMessage({ id: 'sendCusEmail' })}
            </DialogTitle>
            <Box className={classes.emailModalContent}>
              <DialogContent>
                <Input
                  className={classes.username}
                  name="userEmail"
                  type="email"
                  placeholder={intl.formatMessage({ id: 'email' })}
                  onChange={handleInputChange}
                  value={userEmail}
                  required
                />
                <Button
                  className={classes.buttonRefundLeft}
                  onClick={skipUserReceipt}
                >
                  Skip
                </Button>
                <Button
                  className={classes.buttonRefundRight}
                  onClick={handleResendEmail}
                >
                  OK
                </Button>
              </DialogContent>
            </Box>
          </Dialog>
        </Box>
        <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
          {resendReceiptLoading && (
            <Box className={classes.mobileLoaderContainer}>
              <Box className={classes.mobileLoader}>
                <CircularProgress
                  className={classes.circle}
                  size={25}
                  thickness={5}
                ></CircularProgress>
                <Typography>Loading...</Typography>
              </Box>
            </Box>
          )}
        </Hidden>
      </>
    );
  };
  const handleInputChange = (e: any) => {
    setInput(e.target.value);
    reset();
  };
  const resendReceipt = async () => {
    showEmailReceipt(false);
    let params = {
      email_id: userEmail,
      email_type: 'order',
      order_id: order_id,
      store_id: store_id,
    };
    return resendOrderReceipt.create(
      { v1: true, token: cookies.app_token },
      JSON.stringify(params),
    );
  };

  const skipUserReceipt = (e: any) => {
    showEmailReceipt(false);
  };

  const {
    isLoading: resendReceiptLoading,
    mutate: resendUserReceipt,
    reset,
  } = useMutation(resendReceipt, {
    onSuccess: (data: any) => {
      console.log(data);
      if (data?.data?.status === 200) {
        setInput('');
        showToast({
          visible: true,
          message: `Email sent`,
        });
      } else {
        showToast({
          visible: true,
          message: `Error in sending email`,
          severity: 'error',
        });
      }
    },
    onError: error => {
      showToast({
        visible: true,
        message: `Error in sending email`,
        severity: 'error',
      });
    },
  });

  const handleResendEmail = () => {
    if (!userEmail || userEmail?.length === 0) {
      showToast({
        visible: true,
        message: `Please enter email address`,
        severity: 'error',
      });
    } else {
      resendUserReceipt(userEmail);
    }
  };

  const { isLoading: htmlResponseLoading, mutate: getHtmlForPrintReceipt } =
    useMutation(handlePrintReceiptApi, {
      onSuccess: (response: any) => {
        const tab = window.open('about:blank', '_blank');
        tab?.document.write(response.data);
        tab?.document.close();
      },
      onError: (error: any) => {
        datadogLogs.logger.error('ErrorHandler', error);
        console.error('Print Receipt API Error', { error });
        showToast({
          visible: true,
          message: `Failed to print the receipt`,
          severity: 'error',
        });
      },
    });

  const { isLoading: savingOrderNoteLoading, mutate: mutateOrderNote } =
    useMutation(handleSaveOrderNoteApi, {
      onSuccess: (response: any) => {
        if (response?.data?.status === 200) {
          queryClient.invalidateQueries('promoData');
          showToast({
            visible: true,
            message:
              response?.data?.message ||
              intl.formatMessage({ id: 'saveNoteApiSuccess' }),
            severity: 'success',
          });
        } else {
          showToast({
            visible: true,
            message:
              response?.data?.message ||
              intl.formatMessage({ id: 'saveNoteApiError' }),
            severity: 'error',
          });
        }
      },
      onError: (error: any) => {
        showToast({
          visible: true,
          message: intl.formatMessage({ id: 'somethingWrong' }),
          severity: 'error',
        });
      },
    });

  const handleCancelAndRefund = (orderStatusPayload: any) => {
    setCancelLoading(true);
    handleCCOrderStatus(orderStatusPayload).then((ccOrderResponse: any) => {
      let payload = {};
      if (promo_version === 1) {
        payload = {
          order_id,
          store_id,
          return_type: 'FULL',
          return_items: [],
        };
      } else {
        payload = {
          order_id,
          store_id,
          mode: 'F',
          items: [],
        };
      }
      handleOrderRefundApi(payload)
        .then((response: any) => {
          setCancelLoading(false);
          setCancelCCOrder(false);
          queryClient.invalidateQueries('ordersData');
          queryClient.invalidateQueries('promoData');
          queryClient.invalidateQueries('ccOrdersData');
          showToast({
            visible: true,
            message: intl.formatMessage({ id: 'cancelRefundSuccess' }),
            severity: 'success',
          });
        })
        .catch((e: Error) => {
          console.log({ e });
          console.log(`failed order refund`);
          showToast({
            visible: true,
            message: `Failed order refund`,
            severity: 'error',
          });
        });
    });
  };

  const findNonRefundedItemByID = item_id => {
    let non_refunded_items = get(orderData, 'non_refunded_items', []);
    return non_refunded_items.find(item => item.item_id === item_id);
  };

  const handleOrderSummary = (data: any) => {
    let order_notes = get(data, 'order_notes', '');
    let items_available = get(data, 'basket.items_available', {});
    let items_returned = get(data, 'basket.items_returned', {});
    let special_chrgs = [];
    for (let i = 0; i < items_available.no_promos.length; i++) {
      if (
        (findNonRefundedItemByID(
          items_available.no_promos[i].group_items[0]?.item_id,
        )?.hide === false &&
          findNonRefundedItemByID(
            items_available.no_promos[i].group_items[0]?.item_id,
          )?.item_type !== 'REGULAR' &&
          findNonRefundedItemByID(
            items_available.no_promos[i].group_items[0]?.item_id,
          )?.item_type !== null) ||
        (findNonRefundedItemByID(
          items_available.no_promos[i].group_items[0]?.[0]?.item_id,
        )?.hide === false &&
          findNonRefundedItemByID(
            items_available.no_promos[i].group_items[0]?.[0]?.item_id,
          )?.item_type !== 'REGULAR' &&
          findNonRefundedItemByID(
            items_available.no_promos[i].group_items[0]?.[0]?.item_id,
          )?.item_type !== null)
      ) {
        special_chrgs.push(items_available.no_promos[i]);
      }
    }
    let is_price_exclusive_of_tax = get(
      data,
      'is_price_exclusive_of_tax',
      false,
    );
    let show_available =
      get(items_available, 'sns', []).length +
        get(items_available, 'no_promos', []).length +
        get(items_available, 'promos', []).length >
      0;
    let show_returned =
      get(items_returned, 'sns', []).length +
        get(items_returned, 'no_promos', []).length +
        get(items_returned, 'promos', []).length >
      0;
    let total_order_amount = get(data, 'total_order_amount', null),
      total_refunded_amount = get(data, 'total_refunded_amount', null),
      last_refunded_amount = get(data, 'last_refunded_amount', null);
    let order_payment_data = {};
    total_order_amount || total_refunded_amount || last_refunded_amount
      ? (order_payment_data = {
          total_order_amount,
          total_refunded_amount,
          last_refunded_amount,
        })
      : (order_payment_data = {});

    return (
      <>
        {show_returned && (
          <>
            {itemsLayout(
              'refunded',
              items_returned.sns
                ? [
                    ...items_returned.sns,
                    ...items_returned.no_promos,
                    ...items_returned.promos,
                  ]
                : [...items_returned.no_promos, ...items_returned.promos],
              false,
              order_payment_data,
              is_price_exclusive_of_tax,
            )}
          </>
        )}
        {show_available && (
          <>
            {items_available.sns &&
              itemsLayout(
                'sns',
                items_available.sns,
                is_refund_allow,
                {},
                is_price_exclusive_of_tax,
              )}
            {itemsLayout(
              'promos',
              items_available.promos,
              is_refund_allow,
              {},
              is_price_exclusive_of_tax,
            )}
            {itemsLayout(
              'no_promos',
              items_available.no_promos,
              is_refund_allow,
              {},
              is_price_exclusive_of_tax,
            )}
            {itemsLayout(
              'special_charges',
              special_chrgs,
              is_refund_allow,
              {},
              is_price_exclusive_of_tax,
            )}
          </>
        )}
        {is_serial_number_allow && (
          <OrderNote old_note={order_notes} addNoteHandler={mutateOrderNote} />
        )}
      </>
    );
  };

  function generate(
    data: any,
    showRefunds: any,
    type: any,
    is_price_exclusive_of_tax: any,
  ) {
    return data.map((value: any, index: any) => {
      return renderCardContent({
        value,
        index,
        showRefunds,
        type,
        originalData: data,
        is_price_exclusive_of_tax,
      });
    });
  }

  const itemsLayout = (
    type: string,
    data: any,
    showRefunds: boolean = true,
    order_payment_data: any = {},
    is_price_exclusive_of_tax: boolean,
  ) => {
    let color = '',
      title = '',
      titleColor = 'black',
      total = 0;
    switch (type) {
      case 'sns':
        color = '#ffaa00';
        title = intl.formatMessage({ id: 'itemsWithSerialNumber' });
        break;
      case 'promos':
        color = '#ffea26';
        title = intl.formatMessage({ id: 'itemsWithPromo' });
        break;
      case 'no_promos':
        color = '#a6c1ff';
        title = intl.formatMessage({ id: 'itemsWithoutPromo' });
        break;
      case 'special_charges':
        color = '#a6c1ff';
        title = intl.formatMessage({ id: 'specialCharges' });
        break;
      case 'refunded':
        color = '#ffd6d9';
        titleColor = '#ff3d71';
        let { total_refunded_amount } = order_payment_data;
        title = 'Refunded';

        total =
          total_refunded_amount !== null || total_refunded_amount !== undefined
            ? parseFloat(total_refunded_amount)
            : data.reduce((acc: number = 0, val: any): number => {
                try {
                  val.group_items.map((group_item: any) => {
                    let item = Array.isArray(group_item)
                      ? group_item[0]
                      : group_item;
                    let mrp = get(
                      item,
                      promo_version == 1
                        ? 'total_price_of_each_basket_item'
                        : 'mrp',
                      0,
                    );
                    let qty = get(item, 'qty', 1);
                    let accValue = updateDecimals(acc).toFixed(2);
                    acc = parseFloat(accValue) + parseFloat(mrp) * qty;
                  });
                  let totalValue = updateDecimals(acc).toFixed(2);
                  return parseFloat(totalValue);
                } catch (e) {
                  return 0;
                }
              }, 0);
        break;

      default:
        break;
    }

    let doesDataHaveOnlyAddons = data
      .reduce((acc: any, val: any) => {
        if (Array.isArray(val.group_items[0])) {
          return [...acc, ...val.group_items[0]];
        }
        return [...acc, ...val.group_items];
      }, [])
      .some((item: any) => {
        return item?.item_type !== 'ADDON';
      });
    if (data.length && (doesDataHaveOnlyAddons || type === 'special_charges')) {
      return (
        <>
          <Box
            className={classes.promotedLayout}
            style={{ border: `solid 1px ${color}` }}
          >
            <Box
              style={{
                display: 'flex',
                backgroundColor: color,
                padding: 8,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography style={{ color: titleColor, textAlign: 'left' }}>
                {title}
              </Typography>
              {type === 'refunded' ? (
                <Typography>
                  {new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: currencyFormat(currency),
                  }).format(total)}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <List>
              {generate(data, showRefunds, type, is_price_exclusive_of_tax)}
            </List>
          </Box>
        </>
      );
    } else return <></>;
  };

  const excludeTextFormatting = itemName => {
    if (active_store.store_type !== 'MLSEStoreType') {
      return itemName && startCase(itemName);
    } else {
      return itemName;
    }
  };

  const renderCardContent = ({
    value: parent_items,
    index: parent_index,
    showRefunds,
    type,
    originalData,
    is_price_exclusive_of_tax,
  }: any) => {
    let group_title =
        get(parent_items, 'group_title', '') !== 'No Promos'
          ? get(parent_items, 'group_title', '')
          : '',
      group_items_qty_editable = get(
        parent_items,
        'group_items_qty_editable',
        true,
      );
    if (
      parent_items.group_items[0][0]?.item_type !== 'ADDON' ||
      !findNonRefundedItemByID(parent_items.group_items[0][0]?.item_id)?.hide
    ) {
      return (
        <ListItem key={parent_index} style={{ backgroundColor: 'white' }}>
          {parent_items.group_items.map((cardData: any, index: number) => {
            let item = Array.isArray(cardData) ? cardData[0] : cardData;
            let item_product_id = get(item, 'retailer_product_id', '');
            if (item_product_id === null) {
              item_product_id = get(item, 'product_identifier', '');
            }

            let item_name = get(item, 'item_name', ''),
              item_category = get(item, 'item_category', ''),
              item_type = get(item, 'item_type', ''),
              img = get(item, 'img', ''),
              item_id = get(item, 'item_id', ''),
              item_note = get(item, 'extra_data.special_instructions', ''),
              mrpValue = get(
                item,
                promo_version == 1
                  ? 'total_price_of_each_basket_item'
                  : item_type === 'WITH_ADDONS'
                  ? 'mrp_with_addons'
                  : 'mrp',
                '',
              ),
              mrp = `${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyFormat(currency),
              }).format(mrpValue)}`,
              // mrp = `${getCurrency(currency)}${
              //   mrpValue ? updateDecimals(parseFloat(mrpValue)).toFixed(2) : ''
              // }`,
              discountValue = get(
                item,
                promo_version == 1
                  ? 'discount_price'
                  : item_type === 'WITH_ADDONS'
                  ? 'discount_with_addons'
                  : 'discount',
                '',
              ),
              discount = `${
                discountValue && discountValue !== '0'
                  ? `${new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: currencyFormat(currency),
                    }).format(discountValue)}`
                  : '--'
              }`,
              sub_totalValue = get(
                item,
                promo_version == 1
                  ? 'price'
                  : item_type === 'WITH_ADDONS'
                  ? 'sub_total_with_addons'
                  : 'sub_total',
                '',
              ),
              sub_total = `${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyFormat(currency),
              }).format(sub_totalValue)}`,
              // sub_total = `${getCurrency(currency)}${
              //   sub_totalValue
              //     ? updateDecimals(parseFloat(sub_totalValue)).toFixed(2)
              //     : ''
              // }`,
              qty = get(item, promo_version == 1 ? 'item_quantity' : 'qty', ''),
              barcodeData = get(item, 'barcode', '')
                ? get(item, 'barcode', '')
                : get(item, 'product_identifier', ''),
              is_deposit_item = get(item, 'is_deposit_item', false),
              tax_amountValue = get(item, 'tax_amount', ''),
              tax_amount = `${new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currencyFormat(currency),
              }).format(tax_amountValue)}`,
              // tax_amount = `${getCurrency(currency)}${
              //   tax_amountValue
              //     ? updateDecimals(parseFloat(tax_amountValue)).toFixed(2)
              //     : '0'
              // }`,
              item_addons = get(item, 'item_addons', null),
              serial_number = get(item, 'serial_number', null),
              is_rfid_deactivated = get(item, 'is_rfid_deactivated', false);

            const getDiscountValue = () => {
              if (type === 'no_promos') {
                return '--';
              } else if (type === 'refunded') {
                if (promo_version === 1) {
                  return '--';
                } else return discount;
              } else return discount;
            };

            let orderValues = [
              {
                title: intl.formatMessage({ id: 'mrpPerUnit' }),
                value: mrp,
                show: true,
                blank: type === 'special_charges',
              },
              {
                title: intl.formatMessage({ id: 'qty' }),
                value: qty,
                show: true,
                blank: type === 'special_charges',
              },
              {
                title: intl.formatMessage({ id: 'discount' }),
                value: getDiscountValue(),
                show: true,
                blank: type === 'special_charges',
              },
              {
                title: intl.formatMessage({ id: 'tax' }),
                value: tax_amount,
                show: is_price_exclusive_of_tax,
                blank: type === 'special_charges',
              },
              {
                title: intl.formatMessage({ id: 'subtotal' }),
                value: sub_total,
                show: true,
                blank: false,
              },
            ];

            if (item_type === 'ADDON' && type !== 'special_charges') {
              return <Box style={{ display: 'none' }}></Box>;
            }

            return (
              <Box key={item_id} style={{ flex: 1 }}>
                <Box
                  style={{
                    display: 'flex',
                  }}
                >
                  <Box className={classes.imageAndtitleContainer}>
                    {img && (
                      <Box className={classes.itemImageContainer}>
                        <img src={img} className={classes.itemImage} />
                      </Box>
                    )}
                    <Box>
                      <Typography className={classes.itemName}>
                        {startCase(item_name)}
                      </Typography>
                      {type !== 'special_charges' && (
                        <Typography className={classes.itemCategory}>
                          {`${item_category} | ${intl.formatMessage({
                            id: 'productID',
                          })} - ${item_product_id}`}
                        </Typography>
                      )}
                      {item_addons ? (
                        item_addons.map((item: any, index: number) => {
                          return (
                            <Typography className={classes.itemCategory}>
                              {excludeTextFormatting(item?.name)}
                            </Typography>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {item_note && (
                        <Box
                          key={index}
                          className={classes.specialInstructionsContent}
                        >
                          <Typography className={classes.darkRedFont}>
                            {'Special Instructions - ' + item_note}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Hidden xsDown>
                    <Box className={classes.buttonContainer}>
                      <Button
                        style={{
                          visibility:
                            item.item_type !== 'ADDON' &&
                            item.item_type !== 'WITH_ADDONS'
                              ? 'visible'
                              : 'hidden',
                        }}
                        startIcon={<BarcodeShow />}
                        className={classes.barcodeShow}
                        variant="text"
                        size="small"
                        onClick={() =>
                          showBarcode({
                            visible: true,
                            type: 'barcode',
                            data: barcodeData,
                          })
                        }
                      >
                        {intl.formatMessage({ id: 'barcode' })}
                      </Button>
                      {orderValues.map((item: any, index: any) => {
                        return (
                          item.show && (
                            <Box
                              key={index}
                              className={classes.orderSummaryListContent}
                            >
                              <Typography
                                className={classes.lightGreyTextList}
                                style={{
                                  visibility: !item.blank
                                    ? 'visible'
                                    : 'hidden',
                                }}
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                className={classes.darkTextList}
                                style={{
                                  visibility: !item.blank
                                    ? 'visible'
                                    : 'hidden',
                                }}
                              >
                                {item.value}
                              </Typography>
                            </Box>
                          )
                        );
                      })}
                    </Box>
                  </Hidden>
                </Box>
                <Hidden smUp>
                  <Box className={classes.buttonContainer}>
                    <Button
                      style={{
                        visibility:
                          item.item_type !== 'ADDON' &&
                          item.item_type !== 'WITH_ADDONS'
                            ? 'visible'
                            : 'hidden',
                      }}
                      startIcon={<BarcodeShow />}
                      className={classes.barcodeShow}
                      variant="text"
                      size="small"
                      onClick={() =>
                        showBarcode({
                          visible: true,
                          type: 'barcode',
                          data: barcodeData,
                        })
                      }
                    >
                      {intl.formatMessage({ id: 'barcode' })}
                    </Button>
                    <Box style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
                      {orderValues.map((item: any, index: any) => {
                        return (
                          item.show && (
                            <Box
                              key={index}
                              className={classes.orderSummaryListContent}
                            >
                              <Typography className={classes.lightGreyTextList}>
                                {item.title}
                              </Typography>
                              <Typography className={classes.darkTextList}>
                                {item.value}
                              </Typography>
                            </Box>
                          )
                        );
                      })}
                    </Box>
                  </Box>
                </Hidden>
                {serial_number_required && item.serial_number_required && (
                  <Box
                    style={{
                      display: 'flex',
                      // justifyContent:
                      //   serial_number || items_to_capture_sns[item_id]
                      //     ? 'flex-end'
                      //     : 'space-between',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                    }}
                  >
                    {serial_number || items_to_capture_sns[item_id] ? (
                      <></>
                    ) : (
                      <Typography
                        className={classes.promoText}
                        style={{ backgroundColor: '#ffaa00' }}
                      >
                        {intl.formatMessage({ id: 'itemsWithSerialNumber' })}
                      </Typography>
                    )}
                    <Button
                      disabled={
                        !!(order_status === 'verified' && serial_number)
                      }
                      startIcon={
                        <ExternalLinkIcon
                          color={
                            order_status === 'verified' && serial_number
                              ? '#E0E0E0'
                              : '#4180E9'
                          }
                        />
                      }
                      className={classes.serialNumberShow}
                      variant="text"
                      size="large"
                      onClick={() =>
                        showSerialNumberPopUp({
                          show: true,
                          old_sn:
                            serial_number ||
                            items_to_capture_sns[item_id]?.serial_number ||
                            '',
                          new_sn: '',
                          item_id: item_id,
                        })
                      }
                    >
                      {serial_number || items_to_capture_sns[item_id]
                        ? 'SN ' +
                          (serial_number ||
                            items_to_capture_sns[item_id].serial_number)
                        : intl.formatMessage({ id: 'enterSerialNumber' })}
                    </Button>
                  </Box>
                )}
                {(group_title || is_rfid_deactivated) && (
                  <Box className={classes.promoBox}>
                    {group_title && (
                      <Typography className={classes.promoText}>
                        {group_title}
                      </Typography>
                    )}
                    {is_rfid_deactivated && (
                      <Typography className={classes.rfidTag}>
                        {'RFID Deactivated ✅'}
                      </Typography>
                    )}
                  </Box>
                )}
                {showRefunds &&
                  !is_deposit_item &&
                  item.item_type !== 'ADDON' &&
                  item.item_type !== 'WITH_ADDONS' && (
                    <RefundSelector
                      quantity={[...Array(qty + 1).keys()].slice(1)}
                      group_items_qty_editable={group_items_qty_editable}
                      item={item}
                      items_to_refund={items_to_refund}
                      setItemsToRefund={setItemsToRefund}
                      order_status={order_status}
                      cc_order_status={cc_order_status}
                    />
                  )}
                {parent_index < originalData.length - 1 ? (
                  <Divider style={{ marginTop: 8 }} />
                ) : null}
              </Box>
            );
          })}
        </ListItem>
      );
    }
  };

  const renderRefundButtons = (refundCount: number) => {
    return (
      <>
        <Button
          className={classes.buttonRefundLeft}
          variant="outlined"
          disabled={refundLoading}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundLeftRipple },
          }}
          onClick={() => {
            if (refundCount) {
              showAlert(true);
            } else {
              console.log('Show Toast saying - error');
            }
          }}
        >
          {`${intl
            .formatMessage({ id: 'refund' })
            .toUpperCase()}(${refundCount})`}
        </Button>
        <Button
          className={classes.buttonRefundRight}
          variant="contained"
          disabled={refundLoading}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundRightRipple },
          }}
          onClick={() => {
            setRefundAll(true);
            showAlert(true);
          }}
        >
          {intl.formatMessage({ id: 'refundAll' }).toUpperCase()}
        </Button>
      </>
    );
  };

  const renderFooterContent = () => {
    let items_available = get(UIData, 'basket.items_available', {});
    let requiredStatus =
      order_status === 'completed' && serial_number_required
        ? 'serial_number_required'
        : order_status;
    switch (requiredStatus) {
      case 'completed':
        return (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
          >
            <Button
              className={classes.buttonLeft}
              variant="outlined"
              TouchRippleProps={{ classes: { root: classes.buttonLeftRipple } }}
              onClick={() =>
                mutateOrderStatus({
                  order_id: order_id,
                  correct_order_status: false,
                })
              }
            >
              <FormattedMessage id="incorrect" defaultMessage="INCORRECT" />
            </Button>
            <Button
              className={classes.buttonRight}
              variant="contained"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() =>
                mutateOrderStatus({
                  order_id: order_id,
                  correct_order_status: true,
                })
              }
            >
              <FormattedMessage
                id="verifyOrder"
                defaultMessage="VERIFY ORDER"
              />
            </Button>
          </Box>
        );
      case 'serial_number_required':
        return (
          <Box
            className={classes.footer}
            borderTop={1}
            borderColor={theme.palette.color_light_grey.main}
          >
            <Button
              disabled={
                UIData.basket?.items_available?.sns?.length !==
                Object.keys(items_to_capture_sns)?.length
              }
              className={classes.buttonRight}
              variant="contained"
              TouchRippleProps={{
                classes: { root: classes.buttonRightRipple },
              }}
              onClick={() => {
                mutateSerialNumbers();
              }}
            >
              <FormattedMessage id="save" defaultMessage="SAVE" />
            </Button>
          </Box>
        );
      case 'incorrect':
      case 'verification_failed':
      case 'verified':
        let refundCount;
        try {
          refundCount = items_to_refund.reduce(
            (acc: any, val: any) => (acc = acc + val.qty_refund),
            0,
          );
        } catch (e) {
          console.log('Error', e);
        }

        if (
          items_available?.sns?.length +
            items_available?.promos?.length +
            items_available?.no_promos?.length >
            0 &&
          is_refund_allow
        ) {
          return (
            <Box
              className={classes.footer}
              borderTop={1}
              borderColor={theme.palette.color_light_grey.main}
            >
              {renderRefundButtons(refundCount)}
              {is_mark_collected_allowed &&
                cc_order_status !== 'collected' &&
                order_status === 'verified' && (
                  <Button
                    className={classes.buttonRefundRight}
                    variant="contained"
                    disabled={false}
                    TouchRippleProps={{
                      classes: { root: classes.buttonMarkAsCollectedRipple },
                    }}
                    style={{
                      backgroundColor: '#FFAA00',
                      width: 'auto',
                    }}
                    onClick={() => {
                      changeCCOrderStatus({
                        order_id: order_id,
                        status: 'collected',
                      });
                    }}
                  >
                    {intl
                      .formatMessage({ id: 'markASCollected' })
                      .toUpperCase()}
                  </Button>
                )}
            </Box>
          );
        } else return <></>;

      case 'refunded': {
        let refundCount = items_to_refund.reduce(
          (acc: any, val: any) => (acc = acc + val.qty_refund),
          0,
        );
        if (
          items_available?.sns?.length +
            items_available?.promos?.length +
            items_available?.no_promos?.length >
            0 &&
          is_refund_allow
        ) {
          return (
            <Box
              className={classes.footer}
              borderTop={1}
              borderColor={theme.palette.color_light_grey.main}
            >
              {renderRefundButtons(refundCount)}
            </Box>
          );
        } else {
          return <></>;
        }
      }
      default:
        return <></>;
    }
  };

  const getButtonText = () => {
    switch (cc_order_status) {
      case 'created':
        return {
          status: intl.formatMessage({ id: 'acceptOrder' }),
          disabled: false,
          changeStatus: 'accepted',
        };
      case 'accepted':
        return {
          status: intl.formatMessage({ id: 'readyForCollection' }),
          disabled: false,
          changeStatus: 'ready_to_collect',
        };
      case 'ready_to_collect':
        return {
          status: intl.formatMessage({ id: 'collectedByCustomer' }),
          disabled: false,
          changeStatus: 'collected',
        };
      case 'collected':
        return {
          status: intl.formatMessage({ id: 'collected' }),
          disabled: true,
        };
      case 'collected_refunded':
        return {
          status: intl.formatMessage({ id: 'collectedRefund' }),
          disabled: true,
        };
      case 'rejected_customer':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      case 'rejected_store':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      case 'not_collected':
        return {
          status: intl.formatMessage({ id: 'cancelledRefund' }),
          disabled: true,
        };
      default:
        return { status: intl.formatMessage({ id: 'acceptOrder' }) };
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuVisible(false);
    setArrowRef(null);
  };

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };

  const renderCCFooterContent = () => {
    const disableHelp =
      order_status === 'refunded' &&
      (cc_order_status === 'collected' ||
        cc_order_status === 'rejected_store' ||
        cc_order_status === 'rejected_customer' ||
        cc_order_status === 'not_collected');

    const refundOrder =
      cc_order_status === 'collected' ||
      cc_order_status === 'rejected_store' ||
      cc_order_status === 'rejected_customer' ||
      cc_order_status === 'not_collected';

    return (
      <Box
        className={classes.footer}
        borderTop={1}
        borderColor={theme.palette.color_light_grey.main}
      >
        <Button
          className={classes.ccButtonLeft}
          variant="outlined"
          disabled={disableHelp}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundLeftRipple },
          }}
          onClick={(e: any) => {
            if (is_refund_allow) {
              setAnchorEl(!menuVisible ? e.currentTarget : null);
              setMenuVisible(!menuVisible);
            } else {
              showToast({
                ...toast,
                visible: true,
                message: intl.formatMessage({ id: 'noPermissionRefunds' }),
                severity: 'error',
              });
            }
          }}
        >
          <HelpOutlineIcon />
        </Button>
        <Popper
          open={menuVisible}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          style={{
            backgroundColor: 'white',
            zIndex: 5000,
            width: 200,
            marginLeft: 20,
            marginTop: 20,
            marginRight: 20,
          }}
        >
          {({ TransitionProps, placement }: any) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <Paper>
                    <MenuList autoFocusItem={menuVisible} id="menu-list-grow">
                      <MenuItem
                        style={{ width: 200 }}
                        onClick={() => {
                          let selected_items_to_refund_count =
                            reduceItemToRefundCount(items_to_refund);
                          if (selected_items_to_refund_count > 0) {
                            setMenuVisible(false);
                            setAnchorEl(null);
                            setArrowRef(null);
                            showCCAlert(true);
                          } else {
                            setMenuVisible(false);
                            setAnchorEl(null);
                            setArrowRef(null);
                            showCCAlert(false);
                            showToast({
                              ...toast,
                              visible: true,
                              message: intl.formatMessage({
                                id: 'selectItemsAlert',
                              }),
                              severity: 'error',
                            });
                          }
                        }}
                      >
                        {intl.formatMessage({ id: 'refundSelected' })}
                      </MenuItem>
                      <MenuItem
                        style={{ width: 200 }}
                        onClick={() => {
                          setCancelCCOrder(true);
                          setMenuVisible(false);
                          setAnchorEl(null);
                          setArrowRef(null);
                          showCCAlert(true);
                        }}
                      >
                        {refundOrder
                          ? intl.formatMessage({ id: 'refundOrder' })
                          : intl.formatMessage({ id: 'cancelOrder' })}
                      </MenuItem>
                    </MenuList>
                    <span className={classes.arrow} ref={setArrowRef} />
                  </Paper>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          className={classes.ccButtonRight}
          variant="contained"
          disabled={getButtonText().disabled}
          TouchRippleProps={{
            classes: { root: classes.buttonRefundRightRipple },
          }}
          onClick={() =>
            changeCCOrderStatus({
              order_id: order_id,
              status: getButtonText().changeStatus,
            })
          }
        >
          {getButtonText().status?.toUpperCase()}
        </Button>
      </Box>
    );
  };

  const promoData = get(promoResponse, 'data.data', {});
  let date = get(orderData, 'date', ''),
    order_status = get(orderData, 'order_status', ''),
    serial_number_required = get(orderData, 'serial_number_required', ''),
    cc_order_status = get(orderData, 'cc_order_status', ''),
    purchase_type = get(orderData, 'purchase_type', ''),
    discounted_price = get(promoData, 'total_order_amount', ''),
    transaction_id = get(orderData, 'transaction_id', '--'),
    worker_id = get(orderData, 'extra_data.worker_id', ''),
    kiosk_id = get(orderData, 'extra_data.retailer_device_id', ''),
    currency = get(orderData, 'currency', '')
      ? get(orderData, 'currency', '')
      : storeCurrency,
    c_id = get(orderData, 'customer.c_id', ''),
    first_name = get(orderData, 'customer.first_name', ''),
    last_name = get(orderData, 'customer.last_name', ''),
    email = get(orderData, 'customer.is_guest_user', false)
      ? get(orderData, 'customer.guest_user_email', '')
      : get(orderData, 'customer.email', ''),
    payment_method = get(orderData, 'payment_details.payment_method', ''),
    qr_identifier = get(orderData, 'qr_identifier', ''),
    risk_engine_verification_reason = get(
      orderData,
      'risk_engine_verification_reason',
      '',
    ),
    risk_engine_verification_required = get(
      orderData,
      'risk_engine_verification_required',
      false,
    );

  let UIData = constructUIData(
    promo_version,
    orderData,
    promoData,
    serial_number_required,
  );

  const getAvailableItemsBasedOnUIData = useMemo(() => {
    if (UIData && Object.keys(UIData).length > 0) {
      return (
        UIData.basket?.items_available?.sns
          ? [
              ...UIData.basket?.items_available.sns,
              ...UIData.basket?.items_available?.promos,
              ...UIData.basket?.items_available?.no_promos,
            ]
          : [
              ...UIData.basket?.items_available?.promos,
              ...UIData.basket?.items_available?.no_promos,
            ]
      ).reduce((acc: any, val: any) => {
        if (Array.isArray(val.group_items[0])) {
          return [...acc, ...val.group_items[0]];
        }
        return [...acc, ...val.group_items];
      }, []);
    }
    return [];
  }, [UIData]);

  let partialRefund =
    get(orderData, 'non_refunded_items', []).length > 0 &&
    get(orderData, 'refunded_items', []).length > 0;
  let isThisClickAndCollectOrder = false;
  if (type != null) {
    if (type === 'click_and_collect') {
      isThisClickAndCollectOrder = true;
    }
  } else {
    if (purchase_type === 'click_and_collect') {
      isThisClickAndCollectOrder = true;
    }
  }

  let orderSummary = [
    {
      title: intl.formatMessage({ id: 'total' }),
      value: discounted_price
        ? `${new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyFormat(currency),
          }).format(discounted_price)}`
        : '',
      // value: discounted_price
      //   ? getCurrency(currency) +
      //     updateDecimals(parseFloat(discounted_price)).toFixed(2)
      //   : '',
    },
    {
      title: intl.formatMessage({ id: 'items' }),
      value:
        promo_version === 1
          ? getOrderCount(orderData)
          : UIData?.total_number_of_items,
    },
    {
      title: intl.formatMessage({ id: 'basketQr' }),
      value: qr_identifier,
      type: 'qr',
      visible: false,
    },
    {
      title: intl.formatMessage({ id: 'type' }),
      value: intl.formatMessage({ id: 'click&Collect' }),
    },
    {
      title: intl.formatMessage({ id: 'customerId' }),
      value: c_id,
    },
    {
      title: intl.formatMessage({ id: 'customer' }),
      value: first_name + ' ' + last_name,
    },
    {
      title: intl.formatMessage({ id: 'email' }),
      value: email,
    },
    {
      title: intl.formatMessage({ id: 'transactionId' }),
      value: transaction_id,
    },
    {
      title: intl.formatMessage({ id: 'paymentMethod' }),
      value: startCase(payment_method),
    },
    {
      title: intl.formatMessage({ id: 'storeName' }),
      value: retailer,
    },
  ];
  worker_id &&
    worker_id.length &&
    orderSummary.push({
      title: intl.formatMessage({ id: 'workerId' }),
      value: worker_id,
    });
  kiosk_id &&
    kiosk_id.length &&
    orderSummary.push({
      title: intl.formatMessage({ id: 'kioskId' }),
      value: kiosk_id,
    });

  const reduceAvailableCount = (items: any) => {
    return items.reduce(
      (acc: any, val: any) =>
        (acc = val?.item_type === 'ADDON' ? acc : acc + val.item_quantity),
      0,
    );
  };

  const reduceItemToRefundCount = (items: any) => {
    return items.reduce(
      (acc: any, val: any) =>
        (acc = val?.item_type === 'ADDON' ? acc : acc + val.qty_refund),
      0,
    );
  };

  let available_items_count = React.useMemo(
    () => reduceAvailableCount(get(orderData, 'non_refunded_items', [])),
    [orderData],
  );

  const confirmRefunds = () => {
    let available_items_count = reduceAvailableCount(
      get(orderData, 'non_refunded_items', []),
    );

    let selected_items_to_refund_count =
      reduceItemToRefundCount(items_to_refund);

    if (promo_version === 1) {
      if (
        available_items_count === selected_items_to_refund_count ||
        shouldRefundAll
      ) {
        let payload = {
          order_id,
          store_id,
          return_type: 'FULL',
          return_items: items_to_refund,
        };
        triggerRefunds(payload);

        setRefundAll(false);
      } else {
        let payload = {
          order_id,
          store_id,
          return_type: 'PARTIAL',
          return_items: items_to_refund,
        };
        triggerRefunds(payload);
      }
    } else if (promo_version === 2) {
      if (
        available_items_count === selected_items_to_refund_count ||
        shouldRefundAll
      ) {
        //refund all
        let payload = {
          items: [],
          mode: 'F',
          order_id,
          store_id,
        };
        triggerRefunds(payload);
        setRefundAll(false);
      } else {
        //partial refund
        handlePartialRefundforPromoV2();
      }
    }
    showAlert(false);
  };

  const handlePartialRefundforPromoV2 = () => {
    let items_available = [
      ...get(UIData, 'basket.items_available.sns', []),
      ...get(UIData, 'basket.items_available.promos', []),
      ...get(UIData, 'basket.items_available.no_promos', []),
    ].reduce((acc: any, val: any) => {
      try {
        if (Array.isArray(val.group_items[0])) {
          return [...acc, ...val.group_items[0]];
        }
        return [...acc, ...val.group_items];
      } catch (error) {
        return [];
      }
    }, []);

    let items: any = [...items_to_refund];
    items_to_refund?.forEach((item: any) => {
      switch (item?.item_type) {
        case 'WITH_ADDONS': {
          let { item_addons } = item;
          item_addons?.forEach((barcode_addon: any) => {
            let addon = items_available.find((item: any) => {
              try {
                return item.item_id === barcode_addon.item_id;
              } catch (error) {
                return false;
              }
            });
            addon && items.push({ ...addon, qty_refund: item.qty_refund });
          });
          break;
        }
        case 'REGULAR': {
          return;
        }
        case 'ADDON': {
          return;
        }
        default: {
          return;
        }
      }
    });

    let payload = {
      items: items,
      mode: 'P',
      order_id,
      store_id,
    };
    triggerRefunds(payload);
  };

  const initiatePartialOrFullRefund = (shouldCancelOrder: any = false) => {
    let selected_items_to_refund_count =
      reduceItemToRefundCount(items_to_refund);

    const areAllItemsSelected =
      available_items_count === selected_items_to_refund_count;

    if (areAllItemsSelected) {
      if (shouldCancelOrder) {
        handleCancelAndRefund({
          order_id: order_id,
          status:
            cc_order_status === 'ready_to_collect'
              ? 'not_collected'
              : 'rejected_store',
        });
      } else {
        let payload = {};
        if (promo_version === 1) {
          payload = {
            order_id,
            store_id,
            return_type: 'FULL',
            return_items: [],
          };
        } else {
          payload = {
            order_id,
            store_id,
            mode: 'F',
            items: [],
          };
        }
        triggerRefunds(payload);
      }
    } else {
      let payload = {};
      if (promo_version === 1) {
        payload = {
          order_id,
          store_id,
          return_type: 'PARTIAL',
          return_items: items_to_refund,
        };
        triggerRefunds(payload);
      } else {
        handlePartialRefundforPromoV2();
      }
    }
  };

  const ccRefundAvailableStatus = (value: any) =>
    [
      'collected',
      'rejected_store',
      'rejected_customer',
      'not_collected',
    ].includes(value);

  const confirmCCRefunds = () => {
    const isRefundOrder = ccRefundAvailableStatus(cc_order_status);
    if (cancelCCOrder) {
      handleCancelAndRefund({
        order_id: order_id,
        status:
          cc_order_status === 'ready_to_collect'
            ? 'not_collected'
            : 'rejected_store',
      });
    } else if (!isRefundOrder) {
      initiatePartialOrFullRefund(true);
    } else {
      initiatePartialOrFullRefund(false);
    }
    showCCAlert(false);
  };

  const alertModal = () => {
    return (
      <Dialog
        open={alertVisible}
        onClose={() => {
          setRefundAll(false);
          showAlert(false);
        }}
      >
        <DialogTitle>{'Refunds'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'confirmRefund' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRefundAll(false);
              showAlert(false);
            }}
            color="primary"
          >
            {intl.formatMessage({ id: 'no' }).toUpperCase()}
          </Button>
          <Button onClick={confirmRefunds} color="primary" autoFocus>
            {intl.formatMessage({ id: 'yes' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ccAlertModal = () => {
    const refundOrder =
      cc_order_status === 'collected' ||
      cc_order_status === 'rejected_store' ||
      cc_order_status === 'rejected_customer' ||
      cc_order_status === 'not_collected';
    return (
      <Dialog
        open={ccAlertVisible}
        onClose={() => {
          showCCAlert(false);
        }}
      >
        <DialogTitle>{'Refunds'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {refundOrder
              ? intl.formatMessage({ id: 'confirmRefund' })
              : intl.formatMessage({ id: 'cancelAndRefund' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#ff116e', color: '#FFFFFF' }}
            onClick={() => {
              showCCAlert(false);
            }}
          >
            {intl.formatMessage({ id: 'no' }).toUpperCase()}
          </Button>
          <Button
            onClick={confirmCCRefunds}
            style={{ backgroundColor: '#00e58f', color: '#FFFFFF' }}
            autoFocus
          >
            {intl.formatMessage({ id: 'yes' }).toUpperCase()}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const barcodeContent = () => {
    try {
      let barcodeData = barcode.data;
      switch (barcode.type) {
        case 'qr': {
          if (barcode.data) {
            return <QRCode value={barcode.data} size={200} />;
          } else
            return (
              <Typography>
                {intl.formatMessage({ id: 'qrNotFound' })}
              </Typography>
            );
        }
        case 'barcode': {
          if (barcodeData) {
            if (barcodeData.length == 14) {
              if (validateITF14(barcodeData)) {
                return (
                  <Barcode
                    value={barcodeData}
                    format="ITF"
                    height={40}
                    width={1.2}
                    onError={(e: any) => {
                      return null;
                    }}
                  />
                );
              } else return <QRCode value={barcodeData} size={200} />;
            } else if (barcodeData.length == 13) {
              if (validateEAN13(barcodeData)) {
                return (
                  <Barcode
                    value={barcodeData}
                    format={'EAN13'}
                    height={60}
                    width={3}
                    text={barcodeData}
                    flat
                    onError={(e: any) => {
                      return null;
                    }}
                  />
                );
              } else return <QRCode value={barcodeData} size={200} />;
            } else if (barcodeData.length == 12) {
              if (validateUPC(barcodeData)) {
                return (
                  // eslint-disable-next-line
                  <Barcode
                    value={barcodeData}
                    format="UPC"
                    height={40}
                    onError={(e: any) => {
                      return null;
                    }}
                  />
                );
              } else {
                return <QRCode value={barcodeData} size={200} />;
              }
            } else {
              return <QRCode value={barcodeData} size={200} />;
            }
          } else
            return (
              <Typography>
                {intl.formatMessage({ id: 'qrNotFound' })}
              </Typography>
            );
        }
        default:
          return (
            <Typography>{intl.formatMessage({ id: 'qrNotFound' })}</Typography>
          );
      }
    } catch (e) {
      console.log({ e });

      return (
        <Typography>{intl.formatMessage({ id: 'qrNotFound' })}</Typography>
      );
    }
  };

  const barcodeModal = () => {
    return (
      <Dialog open={barcode?.visible} onClose={() => showBarcode(barcodeInit)}>
        <DialogTitle>{intl.formatMessage({ id: 'tapDismiss' })}</DialogTitle>
        <DialogContent>{barcodeContent()}</DialogContent>
      </Dialog>
    );
  };

  const { icon, status, color, variant } = isThisClickAndCollectOrder
    ? order_status === 'refunded' && cc_order_status === 'collected'
      ? orderStatus('collected_refunded', partialRefund)
      : orderStatus(cc_order_status, partialRefund)
    : is_mark_collected_allowed &&
      order_status === 'verified' &&
      cc_order_status === 'collected'
    ? orderStatus('verified_collected', partialRefund)
    : order_status === 'completed' && serial_number_required
    ? orderStatus('serial_number_required', partialRefund)
    : orderStatus(order_status, partialRefund);

  const classes = useStyles({
    b1bg: 'error',
    b2bg: 'success',
    color,
    variant,
  });

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <NavigationTopBar
          title={`${intl.formatMessage({ id: 'order' })} ${display_o_id}`}
          subtitle={
            date
              ? `${moment(date).format('DD/MM/YY HH:mm')} UTC(${moment(date)
                  .utc()
                  .format('HH:mm')})`
              : ''
          }
          refresh={true}
          printReceipt={
            order_status === 'verified' ||
            active_store['store_type'] === 'FlyingTigerStoreType'
          }
          onRefreshClick={() =>
            queryClient.invalidateQueries(['ordersData', 'order_id'])
          }
          onPrintReceiptClick={() => {
            getHtmlForPrintReceipt();
          }}
          onEmailReceiptClick={() => {
            setInput(email);
            showEmailReceipt(true);
            emailModal();
          }}
        />
      </Box>
      <Hidden only={['xs']} implementation="css">
        {(refundLoading ||
          orderStatusLoading ||
          changeCCOrdersLoading ||
          cancelLoading ||
          savingOrderNoteLoading ||
          htmlResponseLoading ||
          serialNumbersLoading) && <LinearProgress color="primary" />}
      </Hidden>
      <Box className={classes.body}>
        {isLoading || promoLoading ? (
          <Box className={classes.loader}>
            <CircularProgress color="primary" size={30} />
          </Box>
        ) : error || Object.keys(orderData).length > 0 ? (
          <Box className={classes.bodyContentContainer}>
            <Box className={classes.orderSummary}>
              <Button
                className={classes.statusButton}
                variant="outlined"
                endIcon={
                  risk_engine_verification_required &&
                  order_status === 'completed' ? (
                    <AlertIcon />
                  ) : (
                    icon
                  )
                }
              >
                {risk_engine_verification_required &&
                order_status === 'completed'
                  ? verificationReasons(
                      risk_engine_verification_reason,
                      null,
                      intl,
                    )
                  : status}
              </Button>
              {orderSummary.map((item: any, index: number) => {
                let { title, value, type, visible } = item;
                if (isThisClickAndCollectOrder && type != 'qr') {
                  return (
                    <Box className={classes.orderSummaryContent} key={index}>
                      <Typography>{title}</Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  );
                } else {
                  switch (type) {
                    case 'qr': {
                      if (value) {
                        return (
                          <Box
                            key={index}
                            className={classes.orderSummaryContent}
                          >
                            <Typography className={classes.lightGreyText}>
                              {title}
                            </Typography>
                            <Button
                              startIcon={<BarcodeShow />}
                              className={classes.barcodeShow}
                              variant="text"
                              size="small"
                              style={{ alignSelf: 'flex-start', height: 18 }}
                              onClick={() =>
                                showBarcode({
                                  visible: true,
                                  type: 'qr',
                                  data: value,
                                })
                              }
                            >
                              {intl.formatMessage({ id: 'barcode' })}
                            </Button>
                          </Box>
                        );
                      } else return <></>;
                    }
                    default: {
                      if (title === 'Type') return null;
                      return (
                        <Box
                          key={index}
                          className={classes.orderSummaryContent}
                        >
                          <Typography className={classes.lightGreyText}>
                            {title}
                          </Typography>
                          <Typography className={classes.darkText}>
                            {value}
                          </Typography>
                        </Box>
                      );
                    }
                  }
                }
              })}
            </Box>
            <Box className={classes.outerBody}>
              <Box className={classes.innerBody}>
                <Typography className={classes.orderSummaryText}>
                  <FormattedMessage
                    id="orderSummary"
                    defaultMessage="Order Summary"
                  />
                </Typography>
                <Box className={classes.orderSummaryUI}>
                  {handleOrderSummary(UIData)}
                </Box>
              </Box>
            </Box>
            {isThisClickAndCollectOrder
              ? renderCCFooterContent()
              : renderFooterContent()}
          </Box>
        ) : (
          <Box className={classes.loader}>
            <Typography>
              <h6>
                <FormattedMessage id="noOrderDetails" />
              </h6>
            </Typography>
          </Box>
        )}
        {alertModal()}
        {ccAlertModal()}
        {emailModal()}
        {barcodeModal()}
        {serialNumberPopUpData.show && (
          <SerialNumber
            {...serialNumberPopUpData}
            handler={(result?: any) => {
              if (result) {
                setItemsForCapturingSNS((draft: any) => {
                  let existingEntry = draft[result.item_id];
                  if (result.new_sn) {
                    if (existingEntry) {
                      draft[result.item_id].serial_number = result.new_sn;
                    } else {
                      draft[result.item_id] = {
                        item_id: result.item_id,
                        serial_number: result.new_sn,
                      };
                    }
                  } else {
                    delete draft[result.item_id];
                  }
                });
              }
              showSerialNumberPopUp(serialNumberPopUpInit);
            }}
          />
        )}
      </Box>
      <Hidden only={['sm', 'md', 'lg', 'xl']} implementation="css">
        {(refundLoading ||
          orderStatusLoading ||
          changeCCOrdersLoading ||
          cancelLoading ||
          savingOrderNoteLoading ||
          htmlResponseLoading ||
          serialNumbersLoading) && (
          <Box className={classes.mobileLoaderContainer}>
            <Box className={classes.mobileLoader}>
              <CircularProgress
                className={classes.circle}
                size={25}
                thickness={5}
              ></CircularProgress>
              <Typography>Loading...</Typography>
            </Box>
          </Box>
        )}
      </Hidden>
      <Snackbar
        open={toast?.visible}
        autoHideDuration={3000}
        onClose={hideToast}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={hideToast} severity={toast?.severity}>
          {toast?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default memo(OrderDetails);
