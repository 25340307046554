import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Hidden,
  Box,
  Grid,
  GridList,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Header from '../../components/Header';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputAndPopper, OrdersCard } from 'src/components';
import { Pagination } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ordersApi } from 'src/services/orders';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import NoOrdersIcon from 'src/assets/no_orders.svg';
import { getSelectedStoreAppClipIds } from 'src/utils';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DateTimeFilterValues } from '../../components/DateTimeFilter';
import moment from 'moment';
import {
  subscriber,
  messageService,
} from 'src/assets/firebaseNotifications/messageService';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
  bodyContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - ${140}px)`,
    },
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${120}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `calc(100% - ${100}px)`,
    },
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  body: {
    flex: 1,
    overflow: 'hidden',
    paddingBottom: 100,
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    backgroundColor: 'white',
    padding: 20,
    [theme.breakpoints.between('md', 'xl')]: {
      left: 350,
    },
    left: 0,
    right: 0,
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },
  resetFilter: {
    background: '#4180E9',
    borderRadius: 4,
    width: 229,
    height: 40,
    fontWeight: 700,
  },
}));

const Orders = () => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  // const [ordersData, setOrdersData] = React.useState([]);
  const queryClient = useQueryClient();
  const [toast, showToast] = useState<any>({
    visible: false,
    message: '',
    severity: 'error',
  });
  const applyFiltersHandler = (filters: DateTimeFilterValues) => {
    filters.order_type = 'scan_and_go';
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        orders: 1,
      },
    });
    dispatch({
      type: 'DATE_TIME_AMOUNT_FILTERS',
      payload: filters,
    });
    fetchOrders();
  };

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout;
    let sub;
    timer = setTimeout(() => {
      clearTimeout(timer);
      sub = subscriber.subscribe(payload => {
        if (payload) {
          showToast({
            visible: true,
            message: `${payload.notification.body}`,
            severity: 'success',
          });
          fetchOrders();
        }
      });
    }, 2000);
    return () => {
      sub && sub.unsubscribe();
    };
  }, [subscriber]);

  const hideToast = () => {
    showToast({
      ...toast,
      visible: false,
    });
  };

  const {
    selected_regions,
    selected_retailers,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const active_stores = useSelector((state: any) => state.store.active_stores);
  const {
    pageNumber,
    pageSize = 10,
    dateTimeAmountFilters: filters,
  } = useSelector((state: any) => state.orders);
  const dateTimeAmountFilters =
    filters && filters.order_type === 'scan_and_go' ? filters : {};
  const { orders: page = 1 } = pageNumber;

  const handlePagination = (event: any, value: any) => {
    dispatch({
      type: 'CHANGE_PAGE_NUMBER',
      payload: {
        ...pageNumber,
        orders: value,
      },
    });
  };

  const handleUSDateFormat = (regions: any) => {
    if (regions.length === 1 && regions.includes('US')) {
      return 'MM/DD/YYYY';
    }
    return 'DD/MM/YYYY';
  };

  const fetchOrdersData = async () => {
    let store_ids = getSelectedStoreAppClipIds(
      stores,
      selected_retailers,
      selected_regions,
      active_stores,
      include_demo_stores,
      include_inactive_stores,
    );
    let regions = selected_regions.map((region: any) => region.value);
    let store_types = selected_retailers.map((retailer: any) => retailer.value);

    let params: any = {
      v1: 'new_base_v3',
      store_types: store_types.join(),
      app_clip_ids: store_ids.join(),
      regions: regions.join(),
      page,
      purchase_type: 'scan_and_go',
      token: cookies.app_token,
      include_demo: include_demo_stores,
      include_inactive: include_inactive_stores,
      page_size: pageSize,
    };
    if (dateTimeAmountFilters.isApplied) {
      if (dateTimeAmountFilters.fromDate && dateTimeAmountFilters.toDate) {
        params.order_date_from = moment(
          moment(dateTimeAmountFilters.fromDate).format(
            handleUSDateFormat(regions),
          ) +
            ' ' +
            dateTimeAmountFilters.fromTime +
            ':00',
          'DD/MM/YYYY HH:mm:ss',
        )
          .utc()
          .format('YYYYMMDDHHmmss');

        params.order_date_to = moment(
          moment(dateTimeAmountFilters.toDate).format(
            handleUSDateFormat(regions),
          ) +
            ' ' +
            dateTimeAmountFilters.toTime +
            ':00',
          'DD/MM/YYYY HH:mm:ss',
        )
          .utc()
          .format('YYYYMMDDHHmmss');
      }
      if (dateTimeAmountFilters.fromPrice && dateTimeAmountFilters.toPrice) {
        params.price_from = dateTimeAmountFilters.fromPrice;
        params.price_to = dateTimeAmountFilters.toPrice;
      }
    }
    return ordersApi.query(params);
  };

  const { isLoading, mutate: fetchOrders } = useMutation(
    [
      'ordersData',
      active_stores,
      page,
      selected_regions,
      selected_retailers,
      active_stores,
      pageSize,
      dateTimeAmountFilters,
    ],
    fetchOrdersData,
    {
      onSuccess: (ordersData: any) => {
        console.log('onSuccess: ', ordersData);
        queryClient.invalidateQueries({ queryKey: ['data'] });
        queryClient.refetchQueries({ queryKey: ['data'] });
        // setOrdersData(data);
      },
    },
  );
  const {
    isLoading: fetchOrdersLoading,
    data: ordersData,
    isFetching,
    error,
  } = useQuery(
    [
      'ordersData',
      active_stores,
      page,
      selected_regions,
      selected_retailers,
      active_stores,
      pageSize,
      dateTimeAmountFilters,
    ],
    fetchOrdersData,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Box className={classes.root}>
      <Header
        dateTimeAmountFilters={dateTimeAmountFilters}
        applyFiltersHandler={applyFiltersHandler}
        title={<FormattedMessage id="orders" defaultMessage="Orders" />}
        subtitle={
          <FormattedMessage
            id="showOrders"
            defaultMessage="Showing orders of"
          />
        }
        order_type="scan_and_go"
      />
      <Hidden only={['lg', 'xl']}>
        <Box className={classes.input}>
          <InputAndPopper
            order_type="scan_and_go"
            dateTimeAmountFilters={dateTimeAmountFilters}
            applyFiltersHandler={applyFiltersHandler}
          />
        </Box>
      </Hidden>
      <Box component="div" className={classes.bodyContainer}>
        {(fetchOrdersLoading || isFetching) && (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={20} />
          </Box>
        )}
        {!isLoading && get(ordersData, 'data.data', []).length === 0 && (
          <Box className={classes.noOrdersContainer}>
            <Box className={classes.noOrders}>
              <Box className={classes.iconContainer}>
                <img className={classes.icon} src={NoOrdersIcon} alt="" />
              </Box>
              <Typography className={classes.noOrdersText}>
                No orders so far
              </Typography>
            </Box>
            {dateTimeAmountFilters.isApplied && (
              <Button
                color="primary"
                variant="contained"
                className={classes.resetFilter}
                onClick={() => {
                  dispatch({
                    type: 'DATE_TIME_AMOUNT_FILTERS',
                    payload: {},
                  });
                }}
              >
                RESET FILTER
              </Button>
            )}
          </Box>
        )}
        {isLoading && (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={20} />
          </Box>
        )}
        <Box component="div" className={classes.body}>
          <GridList className={classes.gridContainer} cols={1}>
            {get(ordersData, 'data.data', []).map(
              (item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={4}
                >
                  <OrdersCard item={item} />
                </Grid>
              ),
            )}
          </GridList>
        </Box>
        {get(ordersData, 'data.data', []).length > 0 && (
          <Paper className={classes.footer}>
            <InputLabel htmlFor="age-simple" style={{ marginRight: 8 }}>
              No. of Items
            </InputLabel>
            <Select
              style={{ marginRight: 8 }}
              value={pageSize}
              onChange={(event: any) => {
                dispatch({
                  type: 'CHANGE_ROWS_PER_PAGE',
                  payload: event.target.value,
                });
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Snackbar
              open={toast?.visible}
              autoHideDuration={3000}
              onClose={hideToast}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Alert onClose={hideToast} severity={toast?.severity}>
                {toast?.message}
              </Alert>
            </Snackbar>
            <Pagination
              count={Math.ceil(get(ordersData, 'data.count', 10) / pageSize)}
              shape="rounded"
              size="medium"
              color="primary"
              page={page}
              onChange={handlePagination}
            />
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default memo(Orders);
