import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Header,
  PieChart,
  TextChart,
  BarChart,
  DatePicker,
} from './components';
import { get } from 'lodash';
import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Link,
  TablePagination,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  itemsScannedApi,
  loginSignupAnalyticsApi,
  ratingsApi,
  retailerBreakdownApi,
  transactionBreakdownApi,
} from 'src/services/analytics';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Masonry from 'react-masonry-css';
import {
  currencyFormat,
  eventBus,
  getCurrency,
  getSelectedStoreAppClipIds,
} from 'src/utils';
import { startCase } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  dateRange: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: 16,
  },
  bodyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    height: '100%',
  },
  body: {
    flex: 0.897,
    overflow: 'hidden',
  },
  gridContainer: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: 20,
    },
  },
  input: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  noOrdersContainer: {
    display: 'flex',
    width: '100%',
    padding: 30,
    flex: 0.897,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noOrders: {
    width: 343,
    height: 367,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6F9FC',
    flexDirection: 'column',
  },

  iconContainer: {
    width: 140,
    height: 140,
    borderRadius: 70,
    backgroundColor: '#EDF1F6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  icon: {
    width: 80,
    height: 80,
  },

  noOrdersText: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 'bold',
    color: '#8f9bb3',
  },
  table: {
    minWidth: 650,
  },
}));

const Analytics = (props: any) => {
  const classes = useStyles();
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    selected_regions,
    regions,
    selected_retailers,
    active_stores,
    retailers,
    stores,
    include_demo_stores,
    include_inactive_stores,
  } = useSelector((state: any) => state.store);
  const {
    analytics: { startDate, endDate },
  } = useSelector((state: any) => state);
  const currencies = [
    { value: 'gbp', title: 'GBP' },
    { value: 'usd', title: 'USD' },
    { value: 'cad', title: 'CAD' },
    { value: 'inr', title: 'INR' },
    { value: 'dkk', title: 'DKK' },
    { value: 'sek', title: 'SEK' },
    { value: 'nok', title: 'NOK' },
    { value: 'chf', title: 'CHF' },
    { value: 'eur', title: 'EUR' },
    { value: 'mvr', title: 'MVR' },
    { value: 'aed', title: 'AED' },
    { value: 'aud', title: 'AUD' },
    { value: 'sar', title: 'SAR' },
    { value: 'aud', title: 'AUD' },
    { value: 'cad', title: 'CAD' },
  ];
  const regionCurrencies = {
    CH: 'CHF',
    CZ: 'CZK',
    DK: 'DKK',
    FI: 'EUR',
    IT: 'EUR',
    NH: 'EUR',
    IR: 'EUR',
    GR: 'EUR',
    FR: 'EUR',
    NL: 'EUR',
    ES: 'EUR',
    EU: 'EUR',
    DE: 'EUR',
    UK: 'GBP',
    GB: 'GBP',
    IL: 'ILS',
    NO: 'NOK',
    SE: 'SEK',
    US: 'USD',
    Sar: 'SAR',
    SA: 'AED',
    AE: 'AED',
    USA: 'USD',
    CA: 'CAD',
    TR: 'TRY',
    IN: 'INR',
    AU: 'AUD',
    RU: 'RUB',
    MV: 'MVR',
  };
  const setCurrencyBySpecialCondition = (cur?) => {
    if (cur?.regions?.length === 1) {
      return regionCurrencies[cur.regions[0].value]?.toLowerCase();
    } else if (
      cur?.retailers?.length === 1 &&
      cur?.retailers?.[0]?.value === 'EventNetworkStoreType'
    ) {
      return 'usd';
    } else if (
      (retailers.length === 1 &&
        retailers[0]?.value === 'EventNetworkStoreType') ||
      (selected_retailers.length === 1 &&
        selected_retailers[0]?.value === 'EventNetworkStoreType')
    ) {
      console.log("retailers[0]?.value === 'EventNetworkStoreType') ||")
      return 'usd';
    } else if (selected_regions.length === 1) {
      console.log('selected_regions.length === 1')
      return (
        regionCurrencies[selected_regions[0]?.value]?.toLowerCase() ||
        currencies[0].value
      );
    } else if (regions.length === 1) {
      return (
        regionCurrencies[regions[0]?.value]?.toLowerCase() ||
        currencies[0].value
      );
    } else if (cur?.length === 0) {
      return currencies[0].value;
    } else {
      return currencies[0].value;
    }
  };
  const [currency, setCurrency] = React.useState(
    setCurrencyBySpecialCondition(),
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [toast, showToast] = React.useState({ visible: false, message: '' });
  const [computedAnalyticsData, setAnalyticsData] = React.useState<any>([]);
  const breakpointColumnsObj = {
    default: 3,
    1500: 3,
    1000: 2,
    550: 1,
  };
  const colors = [
    '#4896ca',
    '#f9ad40',
    '#3cb44b',
    '#41505d',
    '#f2fafb',
    '#f77a7f',
    '#96b4f0',
    '#eba692',
    '#c0d8dc',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#e6194b',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
  ];

  const handleClose = () => {
    showToast({ ...toast, visible: false });
  };

  const appendCurrency = (currency: any, value: any = 0) => {
    try {
      let number = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: currencyFormat(currency),
      }).format(value);
      return number;
    } catch (e) {
      console.log(e);
      return 0;
    }
  };

  const formatValue = (value: string) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchAnalyicsData = async () => {
    try {
      let store_ids = getSelectedStoreAppClipIds(
        stores,
        selected_retailers,
        selected_regions,
        active_stores,
        include_demo_stores,
        include_inactive_stores,
      );
      let regions = selected_regions.map((region: any) => region.value);
      let store_types = selected_retailers.map(
        (retailer: any) => retailer.value,
      );
      let params = {
        v1: 'analytics',
        token: cookies.app_token,
        store_types: store_types.join(),
        app_clip_ids: store_ids.join(),
        regions: regions.join(),
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        include_demo: include_demo_stores,
        include_inactive: include_inactive_stores,
        base_currency: currency,
      };
      let params_v2 = {
        v1: 'analytics_v2',
        token: cookies.app_token,
        store_types: store_types.join(),
        app_clip_ids: store_ids.join(),
        regions: regions.join(),
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        include_demo: include_demo_stores,
        include_inactive: include_inactive_stores,
        base_currency: currency,
      };

      let retailerTransactions = retailerBreakdownApi
          .get({ ...params_v2, base_currency: currency })
          .then((res: any) => res)
          .catch((e: Error) => e),
        transactionBreakdown = transactionBreakdownApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        itemsScanned = itemsScannedApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        ratings = ratingsApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e),
        loginSignupAnalytics = loginSignupAnalyticsApi
          .get(params_v2)
          .then((res: any) => res)
          .catch((e: Error) => e);

      return Promise.allSettled([
        retailerTransactions,
        transactionBreakdown,
        itemsScanned,
        ratings,
        loginSignupAnalytics,
      ]).then(res => {
        let [
          retailerTransactionsRes,
          transactionBreakdownRes,
          itemsScannedRes,
          ratingsRes,
          loginSignupAnalyticsRes,
        ] = res;
        let data = {
          ...get(retailerTransactionsRes, 'value.data.data', {}),
          payment_method_breakdown: get(
            transactionBreakdownRes,
            'value.data.data.payment_method_breakdown',
            {},
          ),
          payment_breakdown_by_platform: get(
            transactionBreakdownRes,
            'value.data.data.platform_breakdown',
            {},
          ),
          ...get(itemsScannedRes, 'value.data.data', {}),
          ...get(ratingsRes, 'value.data.data', {}),
          ...get(loginSignupAnalyticsRes, 'value.data.data', {}),
        };
        constructAnalyticsData(data);
        console.log(
          'total_tax_excluded_basket_value_converted',
          get(analyticsData, 'total_tax_excluded_basket_value_converted', '0'),
        );

        return data;
      });
    } catch (e) {
      console.log({ e });
    }
  };
  let analyticsDisclaimer = 'analyticsDisclaimer';
  const checkLocalTimeDisplay = () => {
    if (active_stores.length > 15) {
      return 'analyticsDisclaimer';
    }
    active_stores?.filter((store: any) => {
      if (
        store?.store_type === 'LovesStoreType' ||
        // store?.store_type === 'GradioseStoreType' ||
        store?.store_type === 'EventNetworkStoreType' ||
        store?.store_type === 'DubaiDutyFreeStoreType'
      ) {
        analyticsDisclaimer = 'analyticsLocalTimeDisclaimer';
      }
    });
    return analyticsDisclaimer;
  };

  const {
    isLoading,
    data: analyticsData,
    isFetching,
    error,
  } = useQuery(
    [
      'analyticsData',
      'store_id',
      startDate,
      endDate,
      selected_regions,
      selected_retailers,
      active_stores,
      currency,
    ],
    fetchAnalyicsData,
    { refetchOnWindowFocus: false, refetchInterval: 180000 },
  );

  const constructAnalyticsData = (analyticsData: any = {}): any => {
    let csData = get(analyticsData, 'rating_breakdown', {});
    let csKeys = Object.keys(csData);
    let payment_method_data = get(
      analyticsData,
      'payment_method_breakdown',
      {},
    );

    let payment_method_keys = Object.keys(payment_method_data).filter(
      (key: any) =>
        [
          'ali_pay',
          'amazon_pay',
          'ideal',
          'mobilepay',
          'swish',
          'wechat_pay',
          'paypal',
          'apple_pay',
          'google_pay',
          'credit_card',
        ].includes(key) || payment_method_data[key],
    );

    const column_1 = [
      {
        type: 'text',
        title: '',
        data: {
          type: 'custom',
          header: {
            title: intl.formatMessage({ id: 'totalTransactions' }),
            value: formatValue(get(analyticsData, 'total_txn_count', '0')),
          },
          body: [
            {
              title: intl.formatMessage({ id: 'averageBasketSize' }),
              value: get(analyticsData, 'average_basket_size', '0'),
            },
            {
              title: intl.formatMessage({ id: 'transactionValue' }),
              value: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(analyticsData, 'total_basket_value_converted', '0'),
                  )
                : '--',
            },
            {
              title: intl.formatMessage({ id: 'vatExcludedVal' }),
              value: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(
                      analyticsData,
                      'total_tax_excluded_basket_value_converted',
                      '0',
                    ),
                  )
                : '--',
            },

            {
              title: intl.formatMessage({ id: 'averageTransactionValue' }),
              value: get(analyticsData, 'use_total_order_value', false)
                ? appendCurrency(
                    currency,
                    get(analyticsData, 'average_order_value', '0'),
                  )
                : '--',
            },
          ],
        },
      },
    ];
    const column_2 = [
      {
        type: 'text',
        title: '',
        data: {
          header: {
            title: intl.formatMessage({ id: 'totalItemPurchased' }),
            value: formatValue(get(analyticsData, 'total_purchased_item', '0')),
          },
          body: [
            {
              title: intl.formatMessage({ id: 'itemsScanned' }),
              value: formatValue(get(analyticsData, 'total_item_scanned', '0')),
            },
            {
              title: intl.formatMessage({ id: 'newUserSignups' }),
              value: formatValue(get(analyticsData, 'signup', '0')),
            },
            {
              title: intl.formatMessage({ id: 'guestUsers' }),
              value: formatValue(get(analyticsData, 'login', '0')),
            },
          ],
        },
      },
      {
        type: 'pie',
        title: intl.formatMessage({ id: 'paymentMethods' }),
        rootDirection: 'column-reverse',
        rootAlignment: 'flex-start',
        size: 'large',
        total: get(analyticsData, 'total_txn_count', '0'),
        data: payment_method_keys.map((item, index) => {
          return {
            value: payment_method_data[item],
            title: startCase(item),
            color: colors[index],
          };
        }),
      },

      {
        type: 'bar',
        title: intl.formatMessage({ id: 'customerSatisfaction' }),
        data: {
          barData: csKeys.map((item, index) => {
            return {
              value: csData[item],
              title: item,
            };
          }),
          textData: {
            title: intl.formatMessage({ id: 'avgExp' }),
            value: get(analyticsData, 'average_rating', '0'),
          },
        },
      },
    ];

    const column_3 = [
      {
        type: 'pie',
        title: intl.formatMessage({ id: 'transactionsByPlatform' }),
        rootDirection: 'column-reverse',
        rootAlignment: 'flex-start',
        total: get(analyticsData, 'total_txn_count', '0'),
        data: [
          {
            key: 1,
            value: get(analyticsData, 'payment_breakdown_by_platform.ios', ''),
            title: 'iOS',
            formatText: false,
            color: colors[0],
          },
          {
            key: 2,
            value:
              get(analyticsData, 'payment_breakdown_by_platform.android', '') -
              (get(
                analyticsData,
                'payment_breakdown_by_platform.android_kiosk',
                '',
              ) +
                get(
                  analyticsData,
                  'payment_breakdown_by_platform.android_ps20',
                  '',
                )),
            title: 'Android',
            color: colors[1],
          },
          {
            key: 3,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_kiosk',
              '',
            ),
            title: 'Android Kiosk',
            color: colors[2],
          },
          {
            key: 4,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_ps20',
              '',
            ),
            title: 'Android PS20',
            color: colors[16],
          },
          {
            key: 5,
            value: get(analyticsData, 'payment_breakdown_by_platform.web', ''),
            title: 'Webapp',
            color: colors[5],
          },
          // TODO: update android_cashier_kiosk key name as per BE implementation (GPP-5294)
          {
            key: 6,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_cashier_kiosk',
              0,
            ),
            title: 'Cashier Kiosk',
            color: colors[6],
          },
          {
            key: 7,
            value: get(
              analyticsData,
              'payment_breakdown_by_platform.android_mpos',
              0,
            ),
            title: 'Android mPOS',
            color: colors[9],
          },
        ],
      },
    ];
    setAnalyticsData([...column_1, ...column_2, ...column_3]);
  };

  const chartIterator = (item: any) => {
    switch (item.type) {
      case 'text':
        return <TextChart data={item.data} />;
      case 'pie':
        return (
          <PieChart
            data={item.data}
            title={item.title}
            rootAlignment={item.rootAlignment}
            rootDirection={item.rootDirection}
            size={item.size}
            total={item.total}
          />
        );
      case 'bar':
        return <BarChart data={item.data} title={item.title} />;
      default:
        return <></>;
    }
  };

  const rightAccessory = () => {
    return (
      <Box className={classes.dateRange}>
        <Box
          style={{
            marginRight: 8,
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <InputLabel id="demo-simple-select-filled-label">Currency</InputLabel>
          <Select
            style={{
              marginTop: 10,
              minWidth: 140,
            }}
            value={currency}
            onChange={(event: any) => {
              setCurrency(event.target.value);
            }}
          >
            {currencies.map((item: any, index: number) => (
              <MenuItem key={index} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <InputLabel shrink>DATE RANGE</InputLabel>
            <Box
              style={{
                display: 'flex',
                flex: 2,
                justifyContent: 'space-evenly',
              }}
            >
              <Link
                style={{ textDecoration: 'none' }}
                component="button"
                variant="body2"
                onClick={() => {
                  dispatch({
                    type: 'CHANGE_ANALYTICS_START_DATE',
                    payload: new Date(),
                  });
                  dispatch({
                    type: 'CHANGE_ANALYTICS_END_DATE',
                    payload: new Date(),
                  });
                }}
              >
                Today
              </Link>
              <Link
                style={{ textDecoration: 'none' }}
                component="button"
                variant="body2"
                onClick={() => {
                  let yesterday = new Date();
                  yesterday.setDate(yesterday.getDate() - 1);
                  dispatch({
                    type: 'CHANGE_ANALYTICS_START_DATE',
                    payload: yesterday,
                  });
                  dispatch({
                    type: 'CHANGE_ANALYTICS_END_DATE',
                    payload: yesterday,
                  });
                }}
              >
                Yesterday
              </Link>
              <Link
                style={{ textDecoration: 'none' }}
                component="button"
                variant="body2"
                onClick={() => {
                  let month = new Date();
                  month.setMonth(month.getMonth() - 1);
                  month.setHours(0, 0, 0, 0);
                  dispatch({
                    type: 'CHANGE_ANALYTICS_START_DATE',
                    payload: month,
                  });
                  dispatch({
                    type: 'CHANGE_ANALYTICS_END_DATE',
                    payload: new Date(),
                  });
                }}
              >
                Last 30 Days
              </Link>
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
            }}
          >
            <DatePicker
              date={startDate}
              endDate={endDate}
              handleDateChange={(dateValue: Date | null) => {
                if (moment(dateValue).isAfter(endDate)) {
                  showToast({
                    ...toast,
                    visible: true,
                    message: 'Start date can not be after end date!',
                  });
                } else {
                  dispatch({
                    type: 'CHANGE_ANALYTICS_START_DATE',
                    payload: dateValue,
                  });
                }
              }}
              style={{ maxWidth: 160, width: 180 }}
            />
            <DatePicker
              startDate={startDate}
              date={endDate}
              handleDateChange={(dateValue: Date | null) => {
                if (moment(dateValue).isBefore(startDate)) {
                  showToast({
                    ...toast,
                    visible: true,
                    message: 'End date can not be after start date!',
                  });
                } else {
                  dispatch({
                    type: 'CHANGE_ANALYTICS_END_DATE',
                    payload: dateValue,
                  });
                }
              }}
              style={{ marginLeft: 8, maxWidth: 180, width: 160 }}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  useEffect(() => {
    eventBus.on('storeSelectorUpdate', data => {
      setCurrency(setCurrencyBySpecialCondition(data));
    });
  }, []);
  useEffect(() => {
    const init = async () => {
      datadogRum.addAction('dashboard-analytics');
    };
    init();
  }, []);

  return (
    <Box className={classes.root}>
      <Header
        title={<FormattedMessage id="analytics" defaultMessage="Analytics" />}
        subtitle={
          <FormattedMessage
            id="reviewPerformance"
            defaultMessage="Review store performance of"
          />
        }
        subtitle2={
          <FormattedMessage
            id={checkLocalTimeDisplay()}
            defaultMessage="*Data is calculated in UTC timezone"
          />
        }
        rightAccessory={rightAccessory}
      />
      <Box className={classes.bodyContainer}>
        {isLoading ? (
          <Box className={classes.noOrdersContainer}>
            <CircularProgress color="primary" size={30} />
          </Box>
        ) : (
          <Box>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {computedAnalyticsData.map((chart: any, index: number) => {
                return <div key={index}>{chartIterator(chart)}</div>;
              })}
            </Masonry>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="sticky table">
                <TableHead style={{ backgroundColor: 'rgb(46, 58, 89)' }}>
                  <TableRow>
                    {[
                      { title: ' ', align: 'left', width: '3%' },
                      { title: 'Store name', align: 'right', width: '25%' },
                      { title: 'Region', align: 'right', width: '10%' },
                      { title: 'Txns', align: 'right', width: '10%' },
                      { title: 'Value', align: 'right', width: '10%' },
                      {
                        title: 'Value (VAT excl.)',
                        align: 'right',
                        width: '10%',
                      },
                      { title: 'AOV', align: 'right', width: '10%' },
                      { title: 'Items', align: 'right', width: '10%' },
                      { title: 'ABS', align: 'right', width: '10%' },
                    ].map((heading: any, idx: number) => {
                      return (
                        <TableCell
                          key={idx}
                          width={heading.width}
                          style={{ color: 'white', fontWeight: 'bold' }}
                        >
                          {heading.title}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analyticsData?.['retailer_breakdown']
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    .map((retailerData: any, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="right">
                            {index + 1 + page * rowsPerPage}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: 'bold' }}
                          >
                            {retailerData.store_name}
                          </TableCell>
                          <TableCell>{retailerData.region}</TableCell>
                          <TableCell align="left">
                            {retailerData.total_retailer_transaction_count}
                          </TableCell>
                          <TableCell align="left">
                            {appendCurrency(
                              currency,
                              retailerData.total_retailer_basket_value_converted,
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {appendCurrency(
                              currency,
                              retailerData.total_retailer_tax_excluded_basket_value_converted,
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {appendCurrency(
                              currency,
                              retailerData.average_retailer_basket_value_converted,
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {retailerData.total_retailer_purchased_item}
                          </TableCell>
                          <TableCell align="left">
                            {retailerData.average_retailer_basket_size}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component={Box}
              count={analyticsData?.['retailer_breakdown']?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>
      <Snackbar
        open={toast.visible}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {toast?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default React.memo(Analytics);
